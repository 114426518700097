import { useKeycloak } from '@baloise/vue-keycloak'
import { useAxios } from '../../use/axios'
import config from '../../config'
import { useAuth } from '../../use/auth'

const keycloak = useKeycloak().keycloak

const keycloakAccountFullUrl = keycloak == null ? undefined : keycloak.createAccountUrl()
const keycloakAccountUrl = keycloakAccountFullUrl == null ? undefined : keycloakAccountFullUrl.split('?')[0]

const { axiosOptions } = useAuth()

export const axiosKeycloakAccount = useAxios(
  axiosOptions({
    config: {
      baseURL: keycloakAccountUrl,
      ...config.keycloak.accountApi
    }
  }))
