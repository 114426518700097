import { axiosApi } from '../../../resources'
import { injectable } from '../../../di'

export class AccountResource {
  public static injectable = injectable(AccountResource)

  async disableMyAccount (): Promise<void> {
    await axiosApi.delete('/account')
  }
}
