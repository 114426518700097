import type { UserData, UserInfoExt } from '../../../use/auth'
import { axiosKeycloakAccount } from '../keycloak-axios'
import { injectable } from '../../../di'

/**
 * @see https://github.com/keycloak/keycloak/blob/master/core/src/main/java/org/keycloak/representations/idm/UserRepresentation.java
 */
export interface UserRepresentation {
  attributes?: Record<string, string[]>
  email: string
  emailVerified: false
  firstName: string
  id: string
  lastName: string
  username: string
  createdTimestamp: number
}

export class KeycloakAccountResource {
  public static injectable = injectable(KeycloakAccountResource)

  async saveUserData (userData: Partial<UserData>, userInfoExt?: Partial<UserInfoExt>): Promise<void> {
    const response = await axiosKeycloakAccount.get('')
    const currentPayload = response.data as UserRepresentation
    const payload: Partial<UserRepresentation> = {
      ...currentPayload,
      lastName: userData.familyName,
      firstName: userData.givenName,
      email: userData.email
    }

    if (userInfoExt?.newsletter != null) {
      if (payload.attributes == null) {
        payload.attributes = {}
      }
      payload.attributes.newsletter = [userInfoExt?.newsletter.toString()]
    }

    if (userData?.language !== undefined) {
      if (payload.attributes == null) {
        payload.attributes = {}
      }

      if (userData.language === null) {
        payload.attributes.language = []
        payload.attributes.locale = []
      } else {
        payload.attributes.language = [userData.language]
        payload.attributes.locale = [userData.language.split('-')[0]]
      }
    }

    await axiosKeycloakAccount.post('', payload)
  }
}
